import React from 'react'

import { navigate } from 'gatsby'
import isEqual from 'lodash.isequal'
import { compiler as markdownCompiler } from 'markdown-to-jsx'
import moment from 'moment'
import queryString from 'query-string'

import { message } from '../components/Message'

import { listRoleCandidates } from '../services/roleCandidates'

import { ROLE_CANDIDATE_REASON_ORIGIN_OPTIONS } from './constants'
import { formatPartnerRoleName } from './role-candidates-helpers'

export const isSSR = typeof window === 'undefined'

export function debounce (func, delay) {
  let timeoutId
  return function (...args) {
    clearTimeout(timeoutId)
    timeoutId = setTimeout(() => func.apply(this, args), delay)
  }
}

export function filledObjectProps (object) {
  return Object.keys(object).reduce((retObj, field) => {
    const value = object[field]
    // Filter out "null" and "undefined" values
    if (value != null) {
      retObj[field] = value
    }
    return retObj
  }, {})
}

export const isNetworkError = message => message === 'Network Error'

export function getSocialAccountUsernameAndType (account) {
  const accountName = account
    .replace(/\/$/, '')
    .replace(/^(.*\/\/)?[^\d/]+\//, '')
    .replace(/^\//, '')
    .replace(/^in\//, '')
    .replace(/^users\//, '')
    .trim()
  const domainName = getDomainName(account)
  return { accountName, domainType: domainName }
}

export function generateSocialAccountHref (accountName, type) {
  if (!(accountName && type)) {
    return null
  }

  switch (type) {
    case 'github':
      return `https://github.com/${accountName}`
    case 'stackoverflow':
      return `https://stackoverflow.com/users/${accountName}`
    case 'linkedin':
      return `https://linkedin.com/in/${accountName}`
    case 'trello':
      return `https://trello.com/c/${accountName}`
    case 'other':
      return ''
    default:
      exhaustiveCheck(type, `Unknown type: ${type}`)
      return ''
  }
}

function exhaustiveCheck (value, message) {
  console.error(message || `Unhandled value: ${value}`)
}

export function isPlainObject (value) {
  return Object.prototype.toString.call(value) === '[object Object]'
}

export const sortByField = field => (a, b) => {
  if (typeof a[field] === 'string' && typeof b[field] === 'string') {
    return a[field]?.toLowerCase().localeCompare(b[field]?.toLowerCase())
  }

  if (a[field] > b[field]) {
    return -1
  }
  if (b[field] > a[field]) {
    return 1
  }
  return 0
}

export function compareStrings (a, b) {
  return String(a).localeCompare(b)
}

export const handleTableRowClick = redirectURL => event => {
  // Links (ie. mailto, regular links) should do their default behavior
  if (event.target.tagName === 'A') {
    event.stopPropagation()
    return
  }

  if (event.ctrlKey || event.metaKey) {
    window.open(redirectURL, '_blank')
    return
  }

  navigate(redirectURL)
}

export const getActiveAnchor = defaultValue => {
  const href = window.location.href.split('#')
  return href.length > 1 ? href[1] : defaultValue
}

export const roleTypes = {
  bizdev: 'bizdev',
  manager: 'manager',
  cavalry: 'cavalry'
}

export const successResponseHandler = (content, duration = 3) => {
  message.success(content, duration)
}

export const errorResponseHandler = error => {
  const res = error.response ?? {}

  const isClientError = res.status >= 400 && res.status <= 499
  if (isClientError) {
    // Strapi uses different formats for validation errors
    // so we handle them all.
    const validationErrors = res.data?.data?.errors ??
      res?.data?.data ?? { error: res?.data?.message }
    const errors = []
    Object.values(validationErrors).forEach(value =>
      errors.push(<li>{value}</li>)
    )
    message.error({
      content: <ul>{errors}</ul>,
      top: 32,
      style: {
        textAlign: 'left'
      }
    })
  } else {
    message.error(error.message || 'Unknown error')
  }
}

export const entityTypes = {
  notes: 'notes',
  experiences: 'experiences',
  screenings: 'profile-screenings',
  jobApplications: 'job-applications'
}

export const getSelectedId = query => {
  if (!query) {
    return
  }
  const { id } = queryString.parse(query)
  return id
}

export const priorityValues = [
  {
    value: -5,
    label: 'Low'
  },
  {
    value: 0,
    label: 'Medium'
  },
  {
    value: 5,
    label: 'High'
  },
  {
    value: 8,
    label: 'Higher'
  },
  {
    value: 10,
    label: 'Immediate'
  }
]

export const createDateSlug = (date = new Date()) => {
  const dd = String(date.getDate()).padStart(2, '0')
  const mm = String(date.getMonth() + 1).padStart(2, '0')
  const yy = String(date.getFullYear()).substring(2)

  return `${yy}${mm}${dd}`
}

export const getArrayWithOpenRoleCandidates = (
  array,
  getProfileId,
  extraParams = {}
) => {
  const result = array.map(async item => {
    const params = {
      profile: getProfileId(item),
      closedAt_null: true,
      ...extraParams
    }
    const { data } = await listRoleCandidates({ params })

    return {
      ...item,
      roleCandidates: data
    }
  })

  return Promise.all(result)
}

export function formatText (text) {
  return text
    ?.replaceAll('\\n\\n', ' </br> ')
    .replaceAll('\n\n', ' </br> ')
    .replaceAll('\\n', ' </br> ')
    .replaceAll('\n', ' </br> ')
}

export const arrayToSelectOptions = (
  array,
  valueKey = 'id',
  labelKey = 'title'
) => {
  const result = array?.map(item => ({
    value: item[valueKey],
    label: item[labelKey]
  }))
  return result
}

// API expects unselected values to be sent as negative values
export const formatNegativeValues = (newValues, previousValues) => {
  // If options hasn't changed, format field to array of numbers
  if (newValues[0] && newValues[0] instanceof Object) {
    return newValues.map(item => item.value)
  }

  const removedOptions = previousValues.reduce((result, item) => {
    if (item.value && !newValues.includes(item.value)) {
      result.push(-item.value)
    } else if (!item.value && !newValues.includes(item)) {
      result.push(-item)
    }
    return result
  }, [])

  return [...newValues, ...removedOptions]
}

export const getDomainName = url => {
  let domainName
  try {
    domainName = new URL(url).hostname.replace('www.', '').split('.')[0]
  } catch (e) {
    return undefined
  }
  return domainName
}

const stripHTML = html => {
  const doc = new DOMParser().parseFromString(html, 'text/html')
  return doc.body.textContent || ''
}

export const getParsedText = value =>
  markdownCompiler(stripHTML(value), {
    overrides: {
      a: {
        component: 'a',
        props: {
          target: '_blank'
        }
      }
    }
  })

export const removeDuplicatedOptions = arr => {
  var result = arr.reduce((unique, o) => {
    if (
      o.value &&
      o.label &&
      !unique.some(obj => obj.label === o.label && obj.value === o.value)
    ) {
      unique.push(o)
    }
    return unique
  }, [])

  return result
}

export const renderDate = date =>
  date ? moment(date).format('YYYY-MM-DD') : '-'

export const buildRoleFitTooltipText = ({ isFit, userName, date, context }) => {
  const status = isFit ? 'Approved' : 'Rejected'
  return `${status} by ${userName} through a ${context} on ${moment(
    date
  ).format('YYYY-MM-DD')}`
}

export const formatCloseReason = ({ summary, origin }) =>
  `${
    ROLE_CANDIDATE_REASON_ORIGIN_OPTIONS.find(item => item.value === origin)
      ?.label
  }: ${summary}`

export const buildTooltipElement = (type, roleCandidate, hideType = false) => {
  const {
    roleCandidateCloseReason,
    partnerRole,
    transferredTo,
    closedAt,
    bookedStartAt,
    bookedEndAt
  } = roleCandidate || {}
  const { name: roleName, partner, id: roleId } = partnerRole || {}
  const { name: partnerName } = partner || {}
  const { partnerRole: newPartnerRole } = transferredTo || {}

  const actionUser = roleCandidate[`${type?.toLowerCase()}By`]
  const date = roleCandidate[`${type?.toLowerCase()}At`]
  const formattedDate = renderDate(date)

  const transferredActionUserText = roleCandidate.closedBy?.username
    ? `by ${roleCandidate.closedBy.username}`
    : ''
  const actionUserText = actionUser?.username
    ? `by ${actionUser?.username}`
    : ''

  const partnerRoleName = formatPartnerRoleName({
    preposition: 'for',
    partnerName,
    roleId,
    roleName
  })
  if (type === 'Closed' && roleCandidateCloseReason) {
    return (
      <>
        {hideType ? '' : type} {partnerRoleName} {actionUserText} -{' '}
        {formatCloseReason(roleCandidateCloseReason)} - ({formattedDate})
      </>
    )
  }

  if (type === 'Transferred') {
    const {
      name: newPartnerRoleName,
      partner: { name: newPartnerName }
    } = newPartnerRole
    const transferredDate = renderDate(closedAt)
    const partnerRoleName = formatPartnerRoleName({
      preposition: 'to',
      partnerName,
      roleId,
      roleName
    })
    return (
      <>
        {hideType ? '' : type} {partnerRoleName} {newPartnerName}:{' '}
        {newPartnerRoleName} {transferredActionUserText} - ({transferredDate})
      </>
    )
  }

  if (type === 'Booked') {
    const startDateValue = bookedStartAt
      ? `Start Date ${renderDate(bookedStartAt)}`
      : 'No Start Date'
    const endDateValue = bookedEndAt
      ? `End Date ${renderDate(bookedEndAt)}`
      : 'No End Date'

    return (
      <>
        {hideType ? '' : type}{' '}
        {formatPartnerRoleName({
          preposition: 'for',
          partnerName,
          roleId,
          roleName
        })}{' '}
        {actionUserText} ({formattedDate}).
        <div>
          {startDateValue} | {endDateValue}
        </div>
      </>
    )
  }

  return (
    <>
      {hideType ? '' : type} {partnerRoleName} {actionUserText} ({formattedDate}
      )
    </>
  )
}

export const getRateRange = targetRate => {
  if (targetRate >= 0 && targetRate < 35) {
    return '0-35'
  } else if (targetRate >= 35 && targetRate < 50) {
    return '35-50'
  } else if (targetRate >= 50 && targetRate < 70) {
    return '50-70'
  } else if (targetRate >= 70 && targetRate < 100) {
    return '70-100'
  } else {
    return '100-'
  }
}

export const convertDaysToFormattedString = numDays => {
  const years = Math.floor(numDays / 365)
  const months = Math.floor((numDays % 365) / 30)
  const days = Math.floor((numDays % 365) % 30)

  let result = ''

  if (years > 0) {
    result += `${years} year${years > 1 ? 's' : ''} `
  }

  if (months > 0) {
    result += `${months} month${months > 1 ? 's' : ''} `
  }

  if (days > 0) {
    result += `${days} day${days > 1 ? 's' : ''}`
  }

  return result.trim()
}

export const groupByKey = (_data, _key) =>
  _data?.reduce((result, next) => {
    const key = next[_key]
    result[key] = result[key]?.length ? [...result[key], next] : [next]
    return result
  }, {})

export const getChangedValues = (values, initialValues) =>
  Object.keys(values).reduce(
    (changedValues, key) => ({
      ...changedValues,
      ...(!isEqual(values[key], initialValues[key])
        ? { [key]: values[key] }
        : {})
    }),
    {}
  )

export function castNumber (number) {
  if (typeof number === 'string' || typeof number === 'number') {
    const numberValue = parseInt(number, 10)
    return isNaN(numberValue) ? NaN : numberValue
  }

  return NaN
}
